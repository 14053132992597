import Icon from "@ant-design/icons";

const ChartSvg = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="2"
        y="2"
        width="20"
        height="20"
        rx="5"
        stroke="white"
        strokeWidth="1.5"
      />
      <path
        d="M8 15.4077L8 12.6887"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 15.4077L12 6.34433"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 15.4077L16 9.06334"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const Chart = (props: any) => <Icon component={ChartSvg} {...props} />;

export default Chart;
