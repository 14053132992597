import Icon from "@ant-design/icons";

const NFTStoreSvg = () => {
  return (
    <svg
      width="24"
      height="19"
      viewBox="0 0 24 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.5074 4.28418H2.5V18.3763H21.5074V4.28418Z"
        stroke="white"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.3083 8.42275L14.8906 10.8776V4.69141H21.3083V8.42275Z"
        stroke="white"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.12471 8.42275L2.70703 11.3401V4.69141H9.12471V8.42275Z"
        stroke="white"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6256 4.28418H9.37891V18.3763H14.6256V4.28418Z"
        stroke="white"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.67578 11.98H14.3285V17.8438H9.67578V11.98ZM9.67578 4.81543V10.6793H14.3285V4.81543H9.67578Z"
        stroke="white"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.0785 6.59465L9.92578 8.57928V5.06543H14.0785V6.59465Z"
        stroke="white"
        strokeWidth="0.5"
      />
      <path
        d="M12.4594 10.6792L14.3296 9.89653V9.24902L10.9688 10.6792H12.4594Z"
        fill="white"
      />
      <path
        d="M14.0785 14.7289L9.92578 16.5942V12.2305H14.0785V14.7289Z"
        fill="#CC9933"
        stroke="white"
        strokeWidth="0.5"
      />
      <path
        d="M21.8351 4.55232H2.23438L4.19425 0.751953H19.8752L21.8351 4.55232Z"
        stroke="white"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.23469 4.5625C2.23469 4.58883 2.23079 4.61515 2.23079 4.64148C2.22725 4.80012 2.25272 4.95793 2.30572 5.10562C2.35871 5.25331 2.43815 5.3879 2.53936 5.50147C2.64057 5.61504 2.76151 5.70529 2.89506 5.76691C3.0286 5.82853 3.17206 5.86028 3.31698 5.86028C3.4619 5.86028 3.60535 5.82853 3.73889 5.76691C3.87244 5.70529 3.99338 5.61504 4.09459 5.50147C4.1958 5.3879 4.27524 5.25331 4.32824 5.10562C4.38123 4.95793 4.4067 4.80012 4.40316 4.64148C4.40316 4.61515 4.40316 4.58883 4.39991 4.5625H2.23469Z"
        fill="#CC9933"
        stroke="white"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.41047 4.5625C4.41047 4.58883 4.40657 4.61515 4.40657 4.64148C4.40303 4.80012 4.42851 4.95793 4.4815 5.10562C4.53449 5.25331 4.61393 5.3879 4.71514 5.50147C4.81636 5.61504 4.93729 5.70529 5.07084 5.76691C5.20439 5.82853 5.34784 5.86028 5.49276 5.86028C5.63768 5.86028 5.78113 5.82853 5.91468 5.76691C6.04822 5.70529 6.16916 5.61504 6.27037 5.50147C6.37158 5.3879 6.45102 5.25331 6.50402 5.10562C6.55701 4.95793 6.58248 4.80012 6.57894 4.64148C6.57894 4.61515 6.57894 4.58883 6.57569 4.5625H4.41047Z"
        fill="#CC9933"
        stroke="white"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.59016 4.5625C6.59016 4.58883 6.58626 4.61515 6.58626 4.64148C6.58272 4.80012 6.60819 4.95793 6.66119 5.10562C6.71418 5.25331 6.79362 5.3879 6.89483 5.50147C6.99604 5.61504 7.11698 5.70529 7.25053 5.76691C7.38407 5.82853 7.52753 5.86028 7.67244 5.86028C7.81736 5.86028 7.96082 5.82853 8.09436 5.76691C8.22791 5.70529 8.34885 5.61504 8.45006 5.50147C8.55127 5.3879 8.63071 5.25331 8.6837 5.10562C8.7367 4.95793 8.76217 4.80012 8.75863 4.64148C8.75863 4.61515 8.75863 4.58883 8.75538 4.5625H6.59016Z"
        fill="#CC9933"
        stroke="white"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.76594 4.5625C8.76594 4.58883 8.76204 4.61515 8.76204 4.64148C8.7585 4.80012 8.78397 4.95793 8.83697 5.10562C8.88996 5.25331 8.9694 5.3879 9.07061 5.50147C9.17182 5.61504 9.29276 5.70529 9.42631 5.76691C9.55985 5.82853 9.70331 5.86028 9.84823 5.86028C9.99314 5.86028 10.1366 5.82853 10.2701 5.76691C10.4037 5.70529 10.5246 5.61504 10.6258 5.50147C10.7271 5.3879 10.8065 5.25331 10.8595 5.10562C10.9125 4.95793 10.938 4.80012 10.9344 4.64148C10.9344 4.61515 10.9344 4.58883 10.9312 4.5625H8.76594Z"
        fill="#CC9933"
        stroke="white"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.9417 4.5625C10.9417 4.58883 10.9378 4.61515 10.9378 4.64148C10.9343 4.80012 10.9598 4.95793 11.0127 5.10562C11.0657 5.25331 11.1452 5.3879 11.2464 5.50147C11.3476 5.61504 11.4685 5.70529 11.6021 5.76691C11.7356 5.82853 11.8791 5.86028 12.024 5.86028C12.1689 5.86028 12.3124 5.82853 12.4459 5.76691C12.5795 5.70529 12.7004 5.61504 12.8016 5.50147C12.9028 5.3879 12.9823 5.25331 13.0353 5.10562C13.0883 4.95793 13.1137 4.80012 13.1102 4.64148C13.1102 4.61515 13.1102 4.58883 13.1069 4.5625H10.9417Z"
        fill="#CC9933"
        stroke="white"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.1175 4.5625C13.1175 4.58883 13.1136 4.61515 13.1136 4.64148C13.1101 4.80012 13.1355 4.95793 13.1885 5.10562C13.2415 5.25331 13.321 5.3879 13.4222 5.50147C13.5234 5.61504 13.6443 5.70529 13.7779 5.76691C13.9114 5.82853 14.0549 5.86028 14.1998 5.86028C14.3447 5.86028 14.4882 5.82853 14.6217 5.76691C14.7553 5.70529 14.8762 5.61504 14.9774 5.50147C15.0786 5.3879 15.1581 5.25331 15.211 5.10562C15.264 4.95793 15.2895 4.80012 15.286 4.64148C15.286 4.61515 15.286 4.58883 15.2827 4.5625H13.1175Z"
        fill="#CC9933"
        stroke="white"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.2972 4.5625C15.2972 4.58883 15.2933 4.61515 15.2933 4.64148C15.2897 4.80012 15.3152 4.95793 15.3682 5.10562C15.4212 5.25331 15.5007 5.3879 15.6019 5.50147C15.7031 5.61504 15.824 5.70529 15.9576 5.76691C16.0911 5.82853 16.2346 5.86028 16.3795 5.86028C16.5244 5.86028 16.6678 5.82853 16.8014 5.76691C16.9349 5.70529 17.0559 5.61504 17.1571 5.50147C17.2583 5.3879 17.3377 5.25331 17.3907 5.10562C17.4437 4.95793 17.4692 4.80012 17.4657 4.64148C17.4657 4.61515 17.4657 4.58883 17.4624 4.5625H15.2972Z"
        fill="#CC9933"
        stroke="white"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.473 4.5625C17.473 4.58883 17.4691 4.61515 17.4691 4.64148C17.4655 4.80012 17.491 4.95793 17.544 5.10562C17.597 5.25331 17.6764 5.3879 17.7776 5.50147C17.8789 5.61504 17.9998 5.70529 18.1333 5.76691C18.2669 5.82853 18.4103 5.86028 18.5553 5.86028C18.7002 5.86028 18.8436 5.82853 18.9772 5.76691C19.1107 5.70529 19.2317 5.61504 19.3329 5.50147C19.4341 5.3879 19.5135 5.25331 19.5665 5.10562C19.6195 4.95793 19.645 4.80012 19.6414 4.64148C19.6414 4.61515 19.6414 4.58883 19.6382 4.5625H17.473Z"
        fill="#CC9933"
        stroke="white"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.6488 4.5625C19.6488 4.58883 19.6449 4.61515 19.6449 4.64148C19.6413 4.80012 19.6668 4.95793 19.7198 5.10562C19.7728 5.25331 19.8522 5.3879 19.9534 5.50147C20.0546 5.61504 20.1756 5.70529 20.3091 5.76691C20.4427 5.82853 20.5861 5.86028 20.731 5.86028C20.876 5.86028 21.0194 5.82853 21.153 5.76691C21.2865 5.70529 21.4074 5.61504 21.5087 5.50147C21.6099 5.3879 21.6893 5.25331 21.7423 5.10562C21.7953 4.95793 21.8208 4.80012 21.8172 4.64148C21.8172 4.61515 21.8172 4.58883 21.814 4.5625H19.6488Z"
        fill="#CC9933"
        stroke="white"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.4375 7.44649L12.0068 6.52148L13.5658 7.44649"
        stroke="#CC9933"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.1622 6.52144C12.1622 6.48823 12.1532 6.45576 12.1363 6.42815C12.1195 6.40053 12.0955 6.37901 12.0675 6.3663C12.0395 6.35359 12.0087 6.35026 11.9789 6.35674C11.9492 6.36322 11.9218 6.37921 11.9004 6.4027C11.8789 6.42618 11.8643 6.45611 11.8584 6.48868C11.8525 6.52125 11.8555 6.55502 11.8671 6.5857C11.8787 6.61639 11.8984 6.64261 11.9236 6.66106C11.9488 6.67952 11.9785 6.68937 12.0088 6.68937C12.0495 6.68937 12.0885 6.67167 12.1173 6.64018C12.146 6.60869 12.1622 6.56598 12.1622 6.52144V6.52144Z"
        fill="#F2F3FF"
        stroke="#02084B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1387 11.7579C14.3146 11.7579 14.4571 11.6018 14.4571 11.4092C14.4571 11.2166 14.3146 11.0605 14.1387 11.0605C13.9629 11.0605 13.8203 11.2166 13.8203 11.4092C13.8203 11.6018 13.9629 11.7579 14.1387 11.7579Z"
        fill="#F2F3FF"
        stroke="#02084B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1398 11.4982C14.1847 11.4982 14.2211 11.4584 14.2211 11.4093C14.2211 11.3601 14.1847 11.3203 14.1398 11.3203C14.095 11.3203 14.0586 11.3601 14.0586 11.4093C14.0586 11.4584 14.095 11.4982 14.1398 11.4982Z"
        fill="#F2F3FF"
        stroke="#02084B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.7836 18.752H0.218341C0.18973 18.7521 0.161367 18.7461 0.134884 18.7343C0.108401 18.7224 0.0843207 18.7049 0.0640287 18.6829C0.0437366 18.6608 0.0276333 18.6345 0.0166447 18.6056C0.00565619 18.5767 -5.12789e-07 18.5456 1.78211e-10 18.5143V18.5143C-1.15973e-06 18.483 0.00565973 18.452 0.0166561 18.4231C0.0276526 18.3942 0.0437665 18.368 0.0640673 18.346C0.0843681 18.324 0.108453 18.3066 0.134933 18.2948C0.161412 18.283 0.189761 18.2771 0.218341 18.2774H23.7836C23.841 18.2774 23.896 18.3023 23.9366 18.3468C23.9772 18.3912 24 18.4515 24 18.5143C24 18.5772 23.9772 18.6375 23.9367 18.6821C23.8961 18.7266 23.8411 18.7518 23.7836 18.752V18.752Z"
        fill="white"
      />
    </svg>
  );
};

const NFTStore = (props: any) => <Icon component={NFTStoreSvg} {...props} />;

export default NFTStore;
