import { createSlice } from "@reduxjs/toolkit";

import { EnvType } from "../../types";

interface EnvironmentState {
  environment: EnvType;
}
const initialState = {
  environment: "LIVE",
} as EnvironmentState;

const envReducer = createSlice({
  name: "environment",
  initialState: initialState,
  reducers: {
    toggleEnvironment: (state) => {
      if (state.environment === "LIVE") state.environment = "TEST";
      else state.environment = "LIVE";
    },
  },
});

export default envReducer.reducer;
export const { toggleEnvironment } = envReducer.actions;
