import React, { Fragment, ReactElement, Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { authRoutes, routes } from "./routes";
import { RouteConfig, RoutesProps } from "./types";
import NotFound from "../../modules/NotFound/404";
import { DashboardShell } from "../Layout/Layout";
import ProtectedRoutes from "./ProtectedRoutes";

/** function to generate routes */
const generateRoutes = (
  { path, Component }: RouteConfig,
  i: any
): ReactElement => {
  return (
    <Route
      key={i}
      path={path}
      element={
        <Suspense fallback={null}>
          <Component />
        </Suspense>
      }
    />
  );
};

const PageRoutes: React.FC<RoutesProps> = () => {
  return (
    <Routes>
      <Route path="/" element={<ProtectedRoutes />}>
        <Route path="/" element={<DashboardShell />}>
          <Route path="/" element={<Navigate to="/home" replace />} />
          {routes.map((route, i) => (
            <Fragment key={i}>{generateRoutes(route, i)}</Fragment>
          ))}
          <Route path="*" element={<NotFound />} />
        </Route>
      </Route>
      {authRoutes.map((route, i) => (
        <Fragment key={i}>{generateRoutes(route, i)}</Fragment>
      ))}
    </Routes>
  );
};

export default PageRoutes;
