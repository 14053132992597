import { createSlice } from "@reduxjs/toolkit";

export interface SettingState {
  showModal: boolean;
  confirmationModal: boolean;
  selectedAdmin: any;
}
const initialState = {
  showModal: false,
  confirmationModal: false,
  selectedAdmin: null,
} as SettingState;

const settingReducer = createSlice({
  name: "setting",
  initialState: initialState,
  reducers: {
    showModal: (state, { payload }) => {
      state.showModal = true;
      state.selectedAdmin = payload;
    },
    closeModal: (state) => {
      state.showModal = false;
    },
    confirmationModal: (state) => {
      state.confirmationModal = true;
    },
    closeConfirmationModal: (state) => {
      state.confirmationModal = false;
    },
  },
  extraReducers: (builder) => {
    /* builder.addCase(getAdminBusiness.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(getAdminBusiness.fulfilled, (state, action) => {
      state.isLoading = false;
    });*/
  },
});

export default settingReducer.reducer;
export const {
  showModal,
  closeModal,
  confirmationModal,
  closeConfirmationModal,
} = settingReducer.actions;
