import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { baseFiatConversionType } from "../../types";

interface GlobalState {
  USDCRate: number;
  USDTRate: number;
  SOLRate: number;
  currencyLoading?: boolean;
  fiatRate: baseFiatConversionType;
  //localCurrency: string,
}
const initialState = {
  USDTRate: 0,
  USDCRate: 0,
  fiatRate: {
    ZARRate: 0,
    GHSRate: 0,
    NGNRate: 0,
    KESRate: 0,
  },
  SOLRate: 0,
  currencyLoading: false,
  //localCurrency: ""
} as GlobalState;

const globalStateReducer = createSlice({
  name: "global",
  initialState: initialState,
  reducers: {
    saveToGlobalState: (state, { payload }: PayloadAction<GlobalState>) => {
      state.USDTRate = payload.USDTRate;
      state.USDCRate = payload.USDCRate;
      state.SOLRate = payload.SOLRate;
      state.fiatRate = payload.fiatRate;
      state.currencyLoading = false;
    },
    currencyLoader: (state, action) => {
      state.currencyLoading = action.payload;
    },
  },
});

export default globalStateReducer.reducer;
export const { saveToGlobalState, currencyLoader } = globalStateReducer.actions;
