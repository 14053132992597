import { useCallback, useEffect } from "react";
import { Layout } from "antd";
import {
  currencyLoader,
  saveToGlobalState,
} from "../../app/global/globalStore";
import { useAppDispatch } from "../../app/hooks";
import {
  fetchFiatCurrency,
  getProfile,
} from "../../modules/Dashboard/service/index";

const { Content } = Layout;

type ShellProps = {
  children?: JSX.Element | JSX.Element[];
  className?: String;
  subnav?: Boolean;
};

const ContentShell = ({ children, subnav, className }: ShellProps) => {
  const dispatch = useAppDispatch();

  const convertCyptoCurrency = useCallback(async () => {
    dispatch(currencyLoader(true));
    try {
      const [
        usdcResponse,
        usdtResponse,
        solResponse,
        zarResponse,
        ngnResponse,
        ghsResponse,
        kesResponse,
      ] = await Promise.all([
        fetchFiatCurrency({ from: "USDC", to: "USD", amount: 1 }),
        fetchFiatCurrency({ from: "USDT", to: "USD", amount: 1 }),
        fetchFiatCurrency({ from: "SOL", to: "USD", amount: 1 }),
        fetchFiatCurrency({ from: "ZAR", to: "USD", amount: 1 }),
        fetchFiatCurrency({ from: "NGN", to: "USD", amount: 1 }),
        fetchFiatCurrency({ from: "GHS", to: "USD", amount: 1 }),
        fetchFiatCurrency({ from: "KES", to: "USD", amount: 1 }),
        //
      ]);
      const usdtRate = usdtResponse?.data.result || 0;
      const usdcRate = usdcResponse?.data.result || 0;
      const solRate = solResponse?.data.result || 0;
      const zarRate = zarResponse?.data.result || 0;
      const ngnRate = ngnResponse?.data.result || 0;
      const ghsRate = ghsResponse?.data.result || 0;
      const kesRate = kesResponse?.data.result || 0;
      dispatch(
        saveToGlobalState({
          USDTRate: usdtRate,
          USDCRate: usdcRate,
          SOLRate: solRate,
          fiatRate: {
            ZARRate: zarRate,
            NGNRate: ngnRate,
            GHSRate: ghsRate,
            KESRate: kesRate,
          },
        })
      );
      dispatch(currencyLoader(false));
      //setTotalBalance()
    } catch (err: any) {
      console.log(err);
      dispatch(currencyLoader(false));
    }
  }, []);

  const fetchUserProfile = useCallback(async () => {
    try {
      await dispatch(getProfile()).unwrap();
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  }, []);

  useEffect(() => {
    fetchUserProfile();
  }, [fetchUserProfile]);

  useEffect(() => {
    convertCyptoCurrency();
  }, [convertCyptoCurrency]);

  return (
    <Content
      className={`app-content ${className ? className : ""}`}
      style={{
        margin: "16px 40px 0",
        overflow: "initial",
        marginTop: "16px",
      }}
    >
      {children}
    </Content>
  );
};

export default ContentShell;
