import { message, notification } from "antd";
import moment from "moment";
import numeral from "numeral";

import Doc from "../../assets/businesses/doc_image.svg";
import Jpg from "../../assets/businesses/jpg_image.svg";
import Pdf from "../../assets/businesses/pdf.svg";
import GhanaFlag from "../../assets/flags/emojione-v1_flag-for-ghana.svg";
import KenyaFlag from "../../assets/flags/emojione-v1_flag-for-kenya.svg";
import SAFlag from "../../assets/flags/emojione-v1_flag-for-south-africa.svg";
import NigeriaFlag from "../../assets/flags/twemoji_flag-nigeria.svg";
import USFlag from "../../assets/flags/twemoji_flag-us-outlying-islands.svg";
import {
  AnalyticsRangeSummary,
  BusinessIdDocument,
  ICheckedList,
  ICountryName,
  ICountryUserDetail,
  IPermission,
  IRolePermission,
  IRolePermissionCheck,
  IRolePermissionType,
  RegistrationPeriod,
  RegistrationType,
  WalletDetailsType,
} from "../../types";
import { ICurrencyType } from "../types";

export const IvoryPayUrl = process.env.REACT_APP_API_URL;

export const REQUEST_TIMEOUT = 60000;

export type baseFiatType = "ZAR" | "GHS" | "KES" | "NGN";

export const getFiatCurrency = (
  val: countryCodeType
): baseFiatType | "USD" | undefined => {
  if (val === "GH") return "GHS";
  if (val === "ZA") return "ZAR";
  if (val === "KE") return "KES";
  if (val === "NG") return "NGN";
  if (val === "US") return "USD";
};

export const defaultDateRange = {
  startPeriodDatetime: "",
  endPeriodDatetime: "",
};

export const defaultQueryParams = (options?: any) => ({
  limit: 10,
  page: 1,
  ...options,
});

export const parseRate = (num: number) => {
  if (num === 0) return 0;
  return +(1 / num).toFixed(2);
};

export const getExchangeRateKey = (str: countryCodeType) => {
  if (str === "GH") return "usdToGHS";
  if (str === "KE") return "usdToKES";
  if (str === "NG") return "usdToNGN";
  if (str === "ZA") return "usdToZAR";
  return null;
};

export const getTheme = (status: "pending" | "delivered" | "cancelled") => {
  if (status === "cancelled")
    return { backgroundColor: "rgba(245, 1, 0, 0.1)", color: "#F50100" };
  if (status === "pending")
    return { backgroundColor: "#FFFAF1", color: "#CC9933" };
  if (status === "delivered")
    return { backgroundColor: "rgba(102, 226, 169, 0.1)", color: "#00CA4E" };
};

export const STORAGE_KEYS = {
  USER_DETAILS_STORAGE_KEY: "ivorypay.user.admin",
  CLIENT_TOKEN_STORAGE_KEY: "ivorypay.token.admin",
};

export type statusType =
  | "none"
  | "Declined"
  | "Pending"
  | "Processing"
  | "Paid"
  | "Active"
  | "Cancelled"
  | "Abandoned"
  | "Reapprove"
  | "Expired"
  | "Inactive"
  | "Suspended"
  | "Approved"
  | "Delivered"
  | "Successful"
  | "Fulfilled"
  | "Failed";

export type countryCodeType = "GH" | "NG" | "ZA" | "KE" | "US";

export type countryType = {
  code: countryCodeType;
  createdAt: Date;
  dialCode: string;
  name: string;
  uuid: string;
};

export type twofactorType = {
  createdAt: Date;
  email: string;
  emailAuth: boolean;
  googleAuth: boolean;
  phoneNumber: string;
  smsAuth: boolean;
  userId: string;
  uuid: string;
};

export const urlPath = {
  auth: "auth",
  admin: "admins",
  profile: "profile",
  transactions: "transactions",
  makeTransaction: "transactions",
  developer: "developer",
  payouts: "payout-requests",
  customers: "business-customers",
  paymentlink: "payment-links",
  devPay: "devRef-Payouts",
  users: "users",
  swaps: "swaps",
  kyc: "kyc",
  currencySetting: "currency-settings",
  stores: "stores",
  businesses: "businesses",
  message: "broadcast-message",
  order: "orders",
  activityLogs: "activity-logs",
  exchangeRate: "exchange-rates",
  analytics: "analytics",
  discounts: "discounts",
};

export const Currency = {
  USDC: "USDC",
  USDT: "USDT",
  IVRY: "$IVRY",
  DAI: "DAI",
};

export async function copyText(text: string) {
  try {
    await navigator.clipboard.writeText(text);
    message.info({ content: "copied", duration: 3 });
  } catch {
    message.error({ content: "unable to copy", duration: 3 });
  }
}

export const getCurrencyRate = (str: baseFiatType) => {
  if (str === "GHS") return "GHSRate";
  if (str === "ZAR") return "ZARRate";
  if (str === "NGN") return "NGNRate";
  if (str === "KES") return "KESRate";
  return "GHSRate";
};

//Currency to 1 dollar
// export const CurrencyRate = {
//   USDC: 0.0015,
//   USDT: 0.0025,
//   IVRY: 0.0038,
//   DAI: 0.002938,
// };

export const toDecimalPlace = 2;

export const CurrencyRate = {
  USDC: {
    name: "USDC",
    rate: 0.0015,
  },
  USDT: {
    name: "USDT",
    rate: 0.0025,
  },
  $IVRY: {
    name: "$IVRY",
    rate: 0.0038,
  },
  DAI: {
    name: "DAI",
    rate: 0.002938,
  },
};

export const getColor = (status: string) => {
  const value = compareStatusType(status);
  if (value === "Declined") return "#F50100";
  if (value === "Abandoned") return "#F50100";
  if (value === "Cancelled") return "#F50100";
  if (value === "Expired") return "#F50100";
  if (value === "Suspended") return "#F50100";
  if (value === "Fulfilled") return "#00CA4E";
  if (value === "Failed") return "#F50100";
  if (value === "Paid") return "#00CA4E";
  if (value === "Delivered") return "#00CA4E";
  if (value === "Active") return "#00CA4E";
  if (value === "Inactive") return "#F50100";
  if (value === "Successful") return "#00CA4E";
  if (value === "Approved") return "#00CA4E";
  if (value === "Pending") return "#FBC03D";
  if (value === "none") return "#FBC03D";
  if (value === "Processing") return "#7B61FF";
  if (value === "Reapprove") return "#7B61FF";
  return "#02084B";
};

export const tagColor = (status: "cancelled" | "pending" | "delivered") => {
  if (status === "cancelled") return "#F50100";
  if (status === "delivered") return "#00CA4E";
  if (status === "pending") return "#FBC03D";
  return "#02084B";
};

export const getCountry = (countryCode: countryCodeType) => {
  if (countryCode === "GH") return "Ghana";
  if (countryCode === "NG") return "Nigeria";
  if (countryCode === "ZA") return "South Africa";
  if (countryCode === "KE") return "Kenya";
  if (countryCode === "US") return "United States";
  return "";
};

export const getCountryFlag = (countryCode: countryCodeType) => {
  if (countryCode === "GH") return GhanaFlag;
  if (countryCode === "NG") return NigeriaFlag;
  if (countryCode === "ZA") return SAFlag;
  if (countryCode === "KE") return KenyaFlag;
  if (countryCode === "US") return USFlag;
  return NigeriaFlag;
};

export const getExchangeLabel = (countryCode: countryCodeType) => {
  if (countryCode === "GH")
    return { heading: "Ghanaian Cedi", text: "Ghanaian Cedi (GHS)" };
  if (countryCode === "KE")
    return { heading: "Kenyan Shilling", text: "Kenyan Shilling (KES)" };
  if (countryCode === "ZA")
    return { heading: "South African Rand", text: "South African Rand (ZAR)" };
  if (countryCode === "NG")
    return { heading: "Nigerian Naira", text: "Nigerian Naira (NGN)" };
  if (countryCode === "US")
    return { heading: "US Dollar", text: "US Dollar (USD)" };
  return { heading: "US Dollar", text: "US Dollar (USD)" };
};

/**
 * Converts the amount and currency inputted, return the value in dollar
 *
 * 1st parameter (currency) is the string unit of currency you're converting from
 *
 * 2nd parameter (amount) is the amount you want to convert to Dollars
 *
 * The 3rd parameter (CurrencyRate), overrides the static rate
 */
export const staticDollarToCurrency = (
  currency: ICurrencyType,
  amount: number = 1,
  currencyRate?: number
) =>
  ((currencyRate || CurrencyRate[currency].rate) * amount).toFixed(
    toDecimalPlace
  );

/**
 * 1st parameter is the unit you're converting to
 *
 * 2nd parameter is the unit you're converting from
 *
 * amount is how much you want to convert
 *
 * currencyRateTo is the rate of the currency you're converting to is to 1 dollar
 *
 * currencyRateFrom is the rate of the currency you' converting from is to 1 dollar
 *
 */
export const staticToCurrencyConversion = (
  currencyTo: ICurrencyType,
  currencyFrom: ICurrencyType,
  amount: number = 1,
  currencyRateTo?: number,
  currencyRateFrom?: number
) => {
  const calcValue = (a: number, b: number, c: number): string =>
    isFinite((a * b) / c)
      ? ((a * b) / c).toFixed(toDecimalPlace)
      : (0).toFixed(toDecimalPlace);

  return calcValue(
    currencyRateTo || +CurrencyRate[currencyTo].rate,
    currencyRateFrom || +CurrencyRate[currencyFrom].rate,
    +amount
  );
  // else return (0).toFixed(toDecimalPlace);
};

export const dateFormat = (date: Date = new Date(Date.now())): string => {
  return moment(date).format("D MMM Y, h:mm a");
};
export const dayFormat = (date: Date = new Date(Date.now())): string => {
  return moment(date).format("D MMM Y");
};
export const timeFormat = (date: Date = new Date(Date.now())): string => {
  return moment(date).format("h:mm a");
};
export const analyticsDateFormat = (date: string): string => {
  return moment(date).format("MMM DD YYYY");
};

export const selectCurrentFile = (e: any) => {
  window.open(productMediaUrl(e), "_blank");
};

export const FormatCurrency = (
  amount: number | string,
  useDecimals?: boolean
) => {
  if (typeof amount === "string") amount = Number(amount);

  return `${numeral(amount).format(useDecimals ? "0,0,0.00" : "0,0,0")}`;
};

export const FormatCryptoCurrency = (
  amount: number | string,
  useDecimals?: boolean
) => {
  if (typeof amount === "string") amount = Number(amount);

  return `${numeral(amount).format(useDecimals ? "0,0,0.[0000]" : "0,0,0")}`;
};

export const parseFilter = (filter: {}) => {
  let filterQuery = "";
  let filterArray = Object.values(filter);

  filterArray.forEach((el) => {
    if (el) filterQuery += `${el},`;
  });
  return filterQuery;
};

export const ParseMoney = (str: number, local?: boolean) => {
  if (!str) return 0;
  const splitStr = str.toString().split(".");
  let formattedCurrency;
  if (splitStr.length === 1) return `${+splitStr[0]}`;

  const decimal = local ? splitStr[1].slice(0, 2) : splitStr[1].slice(0, 4);

  const numerator = +splitStr[0];
  formattedCurrency = numerator.toLocaleString("en-US") + "." + decimal;
  return formattedCurrency;
};

export const countryList = [
  {
    label: "Ghana",
    value: "GH",
  },
  {
    label: "Kenya",
    value: "KE",
  },
  { label: "Nigeria", value: "NG" },
  {
    label: "South Africa",
    value: "ZA",
  },
];

export const productMediaUrl = (id: string) =>
  `${process.env.REACT_APP_MEDIA_URL}/${id}`;

export const storefrontURL = (slug: string) =>
  `${process.env.REACT_APP_STOREFRONT}/${slug}`;

export const ivoryCheckoutUrl = (ref: string) =>
  `${process.env.REACT_APP_IVORYPAY_CHECKOUT}/checkout/${ref}`;

export const ivoryQRPayUrl = (publicId: string) =>
  `${process.env.REACT_APP_IVORYPAY_CHECKOUT}/qr-pay/${publicId}`;

export const compareStatusType = (status: string): statusType => {
  if (!status) return "none";
  if (status.toUpperCase() === "Declined".toUpperCase()) return "Declined";
  if (status.toUpperCase() === "Cancelled".toUpperCase()) return "Cancelled";
  if (status.toUpperCase() === "Abandoned".toUpperCase()) return "Abandoned";
  if (status.toUpperCase() === "Expired".toUpperCase()) return "Expired";
  if (status.toUpperCase() === "Suspended".toUpperCase()) return "Suspended";
  if (status.toUpperCase() === "Failed".toUpperCase()) return "Failed";
  if (status.toUpperCase() === "Paid".toUpperCase()) return "Paid";
  if (status.toUpperCase() === "Active".toUpperCase()) return "Active";
  if (status.toUpperCase() === "Delivered".toUpperCase()) return "Delivered";
  if (status.toUpperCase() === "Inactive".toUpperCase()) return "Inactive";
  if (status.toUpperCase() === "Successful".toUpperCase()) return "Successful";
  if (status.toUpperCase() === "Fulfilled".toUpperCase()) return "Fulfilled";
  if (status.toUpperCase() === "Success".toUpperCase()) return "Successful";
  if (status.toUpperCase() === "Approved".toUpperCase()) return "Approved";
  if (status.toUpperCase() === "None".toUpperCase()) return "none";
  if (status.toUpperCase() === "Pending".toUpperCase()) return "Pending";
  if (status.toUpperCase() === "Reapprove".toUpperCase()) return "Reapprove";
  if (status.toUpperCase() === "Processing".toUpperCase()) return "Processing";
  return "Pending";
};

export const getRegistrationType = (val: RegistrationType) => {
  if (val === "BUSINESS_NAME") return "Business name";
  if (val === "COOPERATIVE_SOCIETY") return "Cooperative society";
  if (val === "FREEZONE_ENTITY") return "Freezone entity";
  if (val === "GOVERNMENT_ENTITY") return "Government entity";
  if (val === "INCORPORATED_COMPANY") return "Incorporated company";
  if (val === "INCORPORATED_TRUSTEES") return "Incorporated trustees";
  if (val === "PRIVATE_ENTITY") return "Private entity";
  return "";
};

export const getRegistrationPeriod = (val: RegistrationPeriod) => {
  if (val === "PRIVATE_2017") return "Privately held (2017-2020)";
  if (val === "PUBLIC_2017") return "Public held (2017-2020)";
  if (val === "PRIVATE_PRE_2017") return "(Pre-2017)";
  // if(val === "PUBLIC_PRE_2017") return "Public held (December 2020 till Date)"
  return "";
};

export const getOrigin = (str: string) => {
  if (!str) return "";
  if (str.toUpperCase() === "PAYMENT_LINK") return "Payment link";
  if (str.toUpperCase() === "QR_PAY") return "QR Pay";
  if (str.toUpperCase() === "IVORY_STORE") return "Storefront";
  if (str.toUpperCase() === "THIRD_PARTY") return "API";
  return "";
};

export const originFilter = [
  {
    label: "API",
    value: "THIRD_PARTY",
  },
  {
    label: "Payment link",
    value: "PAYMENT_LINK",
  },
  {
    label: "Storefront",
    value: "IVORY_STORE",
  },
  {
    label: "QR Pay",
    value: "QR_PAY",
  },
];
export const parseWallet = (wallet: WalletDetailsType[]) => {
  const newWallet = {} as any;
  wallet.forEach((el) => {
    newWallet[el.currency] = el;
  });
  return newWallet;
};

export const FormatMoney = (
  value?: number,
  useDecimals?: boolean,
  symbol?: string
) => {
  if (typeof value === "string") value = Number(value);

  let currency;
  symbol === "ZAR"
    ? (currency = "R")
    : symbol === "GHS"
    ? (currency = "GH₵")
    : symbol === "KES"
    ? (currency = "Ksh")
    : (currency = "₦");

  return `${currency} ${numeral(value).format(
    useDecimals ? "0,0,0.00" : "0,0,0"
  )}`;
};

export const reportErrorMessage = (err: any, message?: string) => {
  let error = err?.message as string;
  if (error && error.toLowerCase() === "internal server error")
    error = "Something went wrong";

  notification.error({
    message: error || message || "Unable to perform action",
    description:
      err.errors && err.errors.map((details: string) => `${details}\n`),
  });
};

//Subject to change/fix
export const countryListForUser = [
  {
    label: "Ghana",
    value: "7bf6ea5a-0f94-490e-9caa-c15e92b1f0ed",
  },
  {
    label: "Kenya",
    value: "4bcdf9ea-8154-4a71-a425-65d34a2d7cd5",
  },
  { label: "Nigeria", value: "817d5f28-0d5c-428d-a910-81dc16c742c9" },
  {
    label: "South Africa",
    value: "52ae41e9-995c-4cef-acca-d5cc430bca0c",
  },
];

const nowTime = moment().toISOString();
const beginningTime = moment("2022-01-02").toISOString();
const lastMonth = moment().subtract(1, "months").toISOString();
const last2Months = moment().subtract(2, "months").toISOString();

export const fromBeginning = {
  startPeriodDatetime: beginningTime,
  endPeriodDatetime: nowTime,
};
export const pastMonth = {
  startPeriodDatetime: lastMonth,
  endPeriodDatetime: nowTime,
};
export const penultimateToLastMonth = {
  startPeriodDatetime: last2Months,
  endPeriodDatetime: lastMonth,
};
export const todayInterval = {
  startPeriodDatetime: moment(moment.now()).startOf("day").toISOString(),
  endPeriodDatetime: moment(moment.now()).endOf("day").toISOString(),
};

export const percentageChange = (prev: number, present: number) => {
  const presentInterval = +present;
  const prevInterval = +prev;
  const sign = +presentInterval > +prevInterval ? 1 : -1;

  if (!prevInterval) return 0;
  return (presentInterval / prevInterval) * 100 * sign;
};

export const getFileType = (doc: string) => {
  const type = doc?.split(".")?.at(-1);

  if (!type) return Pdf;
  if (type === "jpg" || type === "png") return Jpg;
  if (type === "pdf") return Pdf;
  if (type === "doc" || type === "docx") return Doc;
  return Pdf;
};

export const getBusinessOwnerDocument = (doc: BusinessIdDocument) => {
  if (doc === "driversLicence") return "Drivers License";
  if (doc === "interNationalPassport") return "International Passport";
  if (doc === "nationalIdCard") return "National ID Card";
  if (doc === "nimc") return "NIMC";
  if (doc === "votersCard") return "Voters Card";

  return "";
};

export const getCountryDetails = (
  countries: AnalyticsRangeSummary,
  countryName: ICountryName
) => {
  let allTime: ICountryUserDetail = {} as ICountryUserDetail;
  let penultimateToLast: ICountryUserDetail = {} as ICountryUserDetail;
  let lastToNow: ICountryUserDetail = {} as ICountryUserDetail;
  let total = 0;

  allTime = countries?.allTime.find((el: ICountryUserDetail) => {
    return el.name === countryName;
  });
  penultimateToLast = countries?.penultimateToLast.find(
    (el: ICountryUserDetail) => {
      return el.name === countryName;
    }
  );
  lastToNow = countries?.lastToNow.find((el: ICountryUserDetail) => {
    return el.name === countryName;
  });
  countries?.allTime.forEach((el: ICountryUserDetail) => {
    total += +el.numUsers;
  });

  return { allTime, penultimateToLast, lastToNow, total };
};

export const monthUtlis = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const countryColor: Record<string, `#${string}`> = {
  GH: "#B891F8",
  NG: "#616DEB",
  ZA: "#CC9933",
  KE: "#2C3489",
  US: "#000000",
  Ghana: "#B891F8",
  Nigeria: "#616DEB",
  "South Africa": "#CC9933",
  Kenya: "#2C3489",
};
export const parsePermissions = (permissions: {
  [K in IRolePermissionType]: IPermission;
}) => {
  let parsedPermissions: IRolePermissionCheck = {};
  for (let k in permissions) {
    let permission = permissions[k as IRolePermissionType] as {
      name: IRolePermissionType;
      required: 0 | 1;
      scope: string;
    };

    let scopedPermission = {
      name: permission.name,
      required: permission.required,
      scope: permission.scope,
    };

    if (!parsedPermissions[permission.scope]) {
      parsedPermissions[permission.scope] = [];
    }
    parsedPermissions[permission.scope].push(scopedPermission);
  }

  return parsedPermissions;
};

export const flattenRolePermissions = (roles: ICheckedList) => {
  let permissions: IRolePermission = {} as IRolePermission;

  for (let k in roles) {
    for (let i of roles[k]) {
      permissions[i] = 1;
    }
  }

  return permissions;
};

export const compactRolePermissions = (
  roleList: { [K in IRolePermissionType]: IPermission },
  permissions: IRolePermission
): ICheckedList => {
  let commonPermissions: typeof roleList = {} as typeof roleList;

  for (let i in permissions) {
    if (roleList[i as IRolePermissionType]) {
      commonPermissions[i as IRolePermissionType] =
        roleList[i as IRolePermissionType];
    }
  }

  const parsedPermissions = parsePermissions(commonPermissions);
  let compactedPermissions: ICheckedList = {};

  for (let j in parsedPermissions) {
    compactedPermissions[j as string] = Object.values(parsedPermissions[j]).map(
      (e) => e.name
    );
  }
  return compactedPermissions;
};

export const isFulfilled = (
  response: PromiseSettledResult<any>,
  cb: (resResult: PromiseFulfilledResult<any>) => void,
  rej?: (rejResult: PromiseRejectedResult) => void
) => {
  if (response.status === "fulfilled") {
    cb(response.value);
    return;
  }

  rej && rej(response.reason);
  return;
};

export const productImageUrl = (id: string) =>
  `${process.env.REACT_APP_IMAGE_BUCKET}/${id}`;
