import { Layout, Menu } from "antd";
import { useCallback, useEffect, useRef, useState } from "react";
import { NavLink, Outlet, useLocation } from "react-router-dom";

import ActivityLog from "../Icons/ActivityLog";
import Analytics from "../Icons/Analysis";
import Discount from "../Icons/Discount";
import BusinessesIcon from "../Icons/Businesses";
import Chart from "../Icons/Chart";
import CustomerIcon from "../Icons/Customer";
import Dashboard from "../Icons/Dashboard";
import DeveloperIcon from "../Icons/DeveloperIcon";
import IvoryStore from "../Icons/IvoryStore";
import MessagesIcon from "../Icons/Messages";
import NFTStore from "../Icons/NFTStore";
import Payout from "../Icons/Payout";
import Settings from "../Icons/Settings";
import SwapIcon from "../Icons/Swap";
import UsersIcon from "../Icons/Users";
import ContentShell from "./ContentShell";
import Navbar from "./Navbar";

const { Sider } = Layout;

const { SubMenu } = Menu;

export function DashboardShell() {
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const [openKey, setOpenKey] = useState<string[]>([]);
  const location = useLocation();
  const ref = useRef<HTMLDivElement>(null);

  const setOpenKeyByLocation = useCallback(() => {
    let path = location.pathname;
    if (path.includes("home")) return setOpenKey(["home"]);
    if (path.includes("analytics")) return setOpenKey(["analytics"]);
    if (path.includes("discounts")) return setOpenKey(["discounts"]);
    if (path.includes("business")) return setOpenKey(["business"]);
    if (path.includes("users")) return setOpenKey(["users"]);
    if (path.includes("developers")) return setOpenKey(["developers"]);
    if (path.includes("customers")) return setOpenKey(["customer"]);
    if (path.includes("storefront")) return setOpenKey(["ivory-storefront"]);
    if (path.includes("transactions")) return setOpenKey(["transaction"]);
    if (path.includes("payouts")) return setOpenKey(["payout"]);
    if (path.includes("swaps")) return setOpenKey(["swaps"]);
    if (path.includes("messages")) return setOpenKey(["messages"]);
    if (path.includes("transfers")) return setOpenKey(["transfer"]);
    if (path.includes("payment-link")) return setOpenKey(["payment-link"]);
    if (path.includes("settings")) return setOpenKey(["settings"]);
    if (path.includes("activity-logs")) return setOpenKey(["activity-log"]);
    if (path.includes("direct-transfer"))
      return setOpenKey(["direct-transfer"]);
    //if (path.includes("defi")) return setOpenKey(["defi"]);
    //if (path.includes("stacking")) return setOpenKey(["stacking"]);
    //if (path.includes("calculator")) return setOpenKey(["calculator"]);

    return setOpenKey(["home"]);
  }, [location]);

  useEffect(() => {
    setOpenKeyByLocation();
  }, [setOpenKeyByLocation]);

  return (
    <Layout>
      <Sider className={`sidebar ${collapsed ? "" : "open"}`}>
        <div ref={ref}>
          <Menu
            className="mt-12 border-r-0 menu"
            theme="light"
            mode="inline"
            defaultSelectedKeys={["home"]}
            selectedKeys={openKey}
            defaultOpenKeys={["1"]}
          >
            {" "}
            <SubMenu key="1" title="OVERVIEW" className="ivory-submenu">
              <Menu.Item key="home" icon={<Dashboard />}>
                <NavLink to="/home">Dashboard</NavLink>
              </Menu.Item>
              <Menu.Item key="analytics" icon={<Analytics />}>
                <NavLink to="/analytics">Analytics</NavLink>
              </Menu.Item>
            </SubMenu>
            <SubMenu key="2" title="PAYMENTS" className="ivory-submenu">
              <Menu.Item key="transaction" icon={<Chart />}>
                <NavLink to="/transactions">Transaction</NavLink>
              </Menu.Item>

              <Menu.Item key="payout" icon={<Payout />}>
                <NavLink to="/payouts">Payouts</NavLink>
              </Menu.Item>
              <Menu.Item key="direct-transfer" icon={<Chart />}>
                <NavLink to="/direct-transfer">Direct Transfer</NavLink>
              </Menu.Item>
            </SubMenu>
            <SubMenu key="3" title="PEOPLE" className="ivory-submenu">
              <Menu.Item key="users" icon={<UsersIcon />}>
                <NavLink to="/users">Users</NavLink>
              </Menu.Item>
              <Menu.Item key="business" icon={<BusinessesIcon />}>
                <NavLink to="/business">Businesses</NavLink>
              </Menu.Item>
              <Menu.Item key="customer" icon={<CustomerIcon />}>
                <NavLink to="/customers">Customer</NavLink>
              </Menu.Item>
              <Menu.Item key="developers" icon={<DeveloperIcon />}>
                <NavLink to="/developers">Developers</NavLink>
              </Menu.Item>
            </SubMenu>
            <SubMenu key="4" title="PRODUCTS" className="ivory-submenu">
              <Menu.Item key="discounts" icon={<Discount />}>
                <NavLink to="/discounts">Discounts</NavLink>
              </Menu.Item>
              <Menu.Item key="ivory-storefront" icon={<IvoryStore />}>
                <NavLink to="/ivory-storefront">Ivory storefront</NavLink>
              </Menu.Item>

              <Menu.Item
                key="NFT storefront"
                icon={<NFTStore />}
                disabled={true}
              >
                <NavLink to="/nft-storefront">
                  <p style={{ color: "#969ba0" }} className="-mb-5">
                    NFT storefront
                  </p>
                  <span className="text-sm text-[#F1B948]">(coming soon)</span>
                </NavLink>
              </Menu.Item>
            </SubMenu>
            <SubMenu key="7" title="DEFI SERVICES" className="ivory-submenu">
              <Menu.Item key="swaps" icon={<SwapIcon />}>
                <NavLink to="/swaps">Swaps</NavLink>
              </Menu.Item>
            </SubMenu>
            <SubMenu key="5" title="MESSAGES" className="ivory-submenu">
              <Menu.Item key="messages" icon={<MessagesIcon />}>
                <NavLink to="/messages">Broadcast Messages</NavLink>
              </Menu.Item>
            </SubMenu>
            <SubMenu key="6" title="ACCOUNT" className="ivory-submenu">
              <Menu.Item key="activity-log" icon={<ActivityLog />}>
                <NavLink to="/activity-logs">Activity logs</NavLink>
              </Menu.Item>
              <Menu.Item key="settings" icon={<Settings />}>
                <NavLink to="/settings">Settings</NavLink>
              </Menu.Item>
            </SubMenu>
          </Menu>
        </div>
      </Sider>
      <Layout className="ivry-layout">
        <Navbar />

        <ContentShell className="ivory-site-layout ivry-bg">
          <Outlet />
        </ContentShell>
      </Layout>
    </Layout>
  );
}
