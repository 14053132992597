import { createSlice } from "@reduxjs/toolkit";

import { userLogin } from "../service";

export interface AuthState {
  auth: boolean;
  user: {
    uuid: string;
    firstName: string;
    lastName: string;
    type: string;
    email: string;
  };
  isLoading: boolean;
  activeBusinessId: string;
}
const initialState = {
  auth: false,
  user: {
    uuid: "",
    firstName: "",
    lastName: "",
    email: "",
    type: "",
  },
  isLoading: false,
} as AuthState;

const authReducer = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    userAuth: (state) => {
      state.auth = true;
    },
    destoryAuthUser: (state) => {
      state.auth = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userLogin.fulfilled, (state, action) => {
      state.auth = true;
      state.user = action.payload.data;
    });
  },
});

export default authReducer.reducer;
export const { userAuth, destoryAuthUser } = authReducer.actions;
