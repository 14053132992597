import { RcFile } from "antd/es/upload";
import { AxiosError } from "axios";
import moment from "moment";

export function isAxiosError(something: any): something is AxiosError {
  return something?.success === false;
}
export function hasErrorResponse(e: any): e is AxiosError {
  return e && e.response.data;
}
export function isRequestError(e: any): e is AxiosError {
  return e && e.response.data;
}

export const HumanFriendlyDateAndTime = (dateTime: string, format: any) => {
  if (!dateTime) return;
  let _dateTime;
  if (format) {
    _dateTime = moment(dateTime, format);
  } else {
    _dateTime = moment(dateTime);
  }
  return _dateTime.format("DD-MM-YYYY, hh:mma");
};

export const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

export const getBase64Image = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

/*export function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}*/

export const isValueEmptyNull = (value: any) => {
  for (const key in value) {
    if (
      value[key] === null ||
      value[key] === undefined ||
      value[key] === "" ||
      value[key] === 0
    ) {
      delete value[key];
    }
  }
  return value;
};

export const mapIsVerified: { [key: string]: number } = {
  declined: 0,
  pending: 0,
  reapprove: 0,
  approved: 1,
  none: 0,
};