import Icon from "@ant-design/icons";

const DropdownSvg = () => {
  return (
    <svg
      width="12"
      height="10"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.82199 10.8136C7.42431 11.3876 6.57569 11.3876 6.17801 10.8136L0.29315 2.31949C-0.16633 1.65629 0.308326 0.75 1.11514 0.75L12.8849 0.75C13.6917 0.75 14.1663 1.65629 13.7069 2.3195L7.82199 10.8136Z"
        fill="#2C3489"
      />
    </svg>
  );
};

const DropdownIcon = ({ props, className }: any) => (
  <Icon className={className} component={DropdownSvg} {...props} />
);

export default DropdownIcon;
