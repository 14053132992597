import Icon from "@ant-design/icons";

const LogoutSvg = () => {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 13V15C8 15.5304 8.21071 16.0391 8.58579 16.4142C8.96086 16.7893 9.46957 17 10 17H17C17.5304 17 18.0391 16.7893 18.4142 16.4142C18.7893 16.0391 19 15.5304 19 15V3C19 2.46957 18.7893 1.96086 18.4142 1.58579C18.0391 1.21071 17.5304 1 17 1H10C9.46957 1 8.96086 1.21071 8.58579 1.58579C8.21071 1.96086 8 2.46957 8 3V5"
        stroke="#E31D1C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 9H1L4 12M4 6L1 9"
        stroke="#E31D1C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const Logout = (props: any) => <Icon component={LogoutSvg} {...props} />;

export default Logout;
