import axios from "axios";

import { createAsyncThunk } from "@reduxjs/toolkit";

import { urlPath } from "../../../app/config/constants";
import { setUserToken } from "../../../app/config/storage";
import { Requests } from "../../../app/httpRequest";
import store from "../../../app/store";
import {
  hasErrorResponse,
  isAxiosError,
} from "../../../components/Utils/helper";

// const { en} = store.getState()

const { auth, profile } = urlPath;

export const userLogin = createAsyncThunk(
  "auth/user",
  async (data: any, { rejectWithValue }) => {
    try {
      let res = await axios.post(
        `${process.env.REACT_APP_API_URL}/${auth}/admin/login`,
        data
      );
      if (res?.data?.success) {
        setUserToken(res.data.data.token);
        return res.data;
      } else {
        return rejectWithValue(data);
      }
    } catch (err) {
      if (hasErrorResponse(err)) {
        if (!err.response) {
          throw err;
        }
        return rejectWithValue(err.response.data);
      }
      throw new Error("Something went wrong");
    }
  }
);

export const fetchCountries = async () => {
  try {
    let res = await Requests.get(`countries`, null, null);
    return Promise.resolve(res);
  } catch (err) {
    if (isAxiosError(err) && err.response)
      return Promise.reject(err.response.data);
    throw err;
  }
};
