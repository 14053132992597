import { createAsyncThunk } from "@reduxjs/toolkit";

import { urlPath } from "../../../app/config/constants";
import { Requests } from "../../../app/httpRequest";
import store from "../../../app/store";
import { isAxiosError } from "../../../components/Utils/helper";
import { exchangeRateType } from "../../../types";

const { profile } = urlPath;

export const getProfile = createAsyncThunk(
  "profile",
  async (_, { rejectWithValue }) => {
    try {
      let res = await Requests.get(`${profile}`, null, null);
      return res;
    } catch (err) {
      if (isAxiosError(err) && err.response)
        return rejectWithValue(err.response.data);
      throw err;
    }
  }
);

export const fetchFiatCurrency = async (
  query: any
): Promise<{ data: exchangeRateType } | undefined> => {
  try {
    let res = await Requests.get(
      `exchange-rates/cryptofiat-conversion`,
      null,
      query
    );
    if (res?.success) return Promise.resolve(res);
  } catch (err) {
    if (isAxiosError(err) && err.response)
      return Promise.reject(err.response.data);
    return Promise.reject(err);
  }
};

export const getGlobalWallet = async () => {
  try {
    const { environment } = store.getState().env;

    let res = await Requests.get(`general-wallets`, null, { environment });
    if (res?.success) return Promise.resolve(res);
  } catch (err) {
    if (isAxiosError(err) && err.response)
      return Promise.reject(err.response.data);
    return Promise.reject(err);
  }
};
