import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";

const ProtectedRoutes: React.FC = () => {
  const auth = useAppSelector((s) => s.auth.auth);
  //! change this later for authentication
  // let auth = true

  return auth ? <Outlet /> : <Navigate to="/login" />;
};

export default ProtectedRoutes;
