import { STORAGE_KEYS } from "./constants";

export const fetchUserToken = () => {
  return localStorage.getItem(STORAGE_KEYS.CLIENT_TOKEN_STORAGE_KEY);
};

export const clearData = () => {
  return localStorage.clear();
};

export const setUserToken = (token: string) => {
  return localStorage.setItem(STORAGE_KEYS.CLIENT_TOKEN_STORAGE_KEY, token);
};
