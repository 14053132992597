import { combineReducers } from "@reduxjs/toolkit";

import envReducer from "../app/environmentStore/envStore";
import authReducer from "../modules/Auth/store/authSlice";
import settingReducer from "../modules/Settings/store/settingSlice";
import globalStore from "./global/globalStore";

const rootReducer = combineReducers({
  env: envReducer,
  auth: authReducer,
  setting: settingReducer,
  globalState: globalStore,
});

export default rootReducer;
