import React, { useEffect } from "react";
import PageRoutes from "./components/Routes";
import { rudderInitialize } from "./components/Utils/rudderstack";

function App() {
  useEffect(() => {
    rudderInitialize();
  }, []);

  return (
    <>
      <PageRoutes />
    </>
  );
}

export default App;
