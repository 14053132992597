import { Dropdown, Menu } from "antd";
import { useDispatch } from "react-redux";

import Logout from "../Icons/Logout";
import DropdownIcon from "../Icons/DropdownIcon";
import AdminIcon from "../../assets/dashboard/Admin icon.svg";
import { destoryAuthUser } from "../../modules/Auth/store/authSlice";

export default function ProfileDropdown() {
  const dispatch = useDispatch();

  function logout() {
    dispatch(destoryAuthUser());
  }

  const menu = (
    <Menu className="border-r-0 menu" theme="light">
      <Menu.Item key="Logout" icon={<Logout />}>
        <button onClick={logout}>
          <p className="text-[#E31D1C] pl-3">Logout</p>
        </button>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Dropdown overlay={menu} trigger={["click"]} placement="bottomLeft">
        <a
          onClick={(e) => e.preventDefault()}
          className="inline-flex items-center gap-x-2"
        >
          <span className="inline-flex items-center gap-2">
            <span>
              <img src={AdminIcon} className="w-6 h-6" alt="" />
            </span>
            <p className="font-medium text-[#02084B] text-lg">Admin</p>
          </span>
          <>
            <DropdownIcon className="ml-1" />
          </>
        </a>
      </Dropdown>
    </>
  );
}
