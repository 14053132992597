import { Layout, Switch } from "antd";
import { toggleEnvironment } from "../../app/environmentStore/envStore";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import ProfileDropdown from "../Model/ProfileDropdown";

const { Header } = Layout;

const Navbar = () => {
  const { environment } = useAppSelector((state) => state.env);
  const dispatch = useAppDispatch();

  const onChange = () => {
    dispatch(toggleEnvironment());
    window.location.reload();
  };

  return (
    <>
      <Header
        style={{ height: 70 }}
        className="site-layout-background bg-white md:border-b border-gray-200 border-opacity-70 border-solid 
        flex items-center justify-between mk-nav md:px-7 px-5 relative"
      >
        <div className="flex items-center ml-auto w-[17rem] justify-end gap-x-4">
          <div className="flex gap-2 items-center">
            <Switch
              className={environment === "LIVE" ? `bg-[green]` : `bg-[#CC9933]`}
              checked={environment === "LIVE" ? true : false}
              onChange={onChange}
            />
            <p className="capitalize">{environment} mode</p>
          </div>
          <div className="cursor-pointer grid items-center grid-cols-1">
            <ProfileDropdown />
          </div>
        </div>
      </Header>
    </>
  );
};

export default Navbar;
