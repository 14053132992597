import { lazy } from "react";

import { RouteConfig } from "./types";

/**  function to lazy-load routes */
const loadModules = (link: string) =>
  lazy(() => import(`../../modules/${link}`));

export const routes: RouteConfig[] = [
  {
    path: "home",
    Component: loadModules("Dashboard/Dashboard"),
  },
  {
    path: "analytics",
    Component: loadModules("Analytics/Analytics"),
  },
  {
    path: "activity-logs",
    Component: loadModules("ActivityLogs/ActivityLogs"),
  },
  {
    path: "messages",
    Component: loadModules("BroadcastMessage/BroadcastMessages"),
  },
  {
    path: "messages/create",
    Component: loadModules("BroadcastMessage/CreateBroadcastMessage"),
  },
  {
    path: "messages/preview",
    Component: loadModules("BroadcastMessage/PreviewMessage"),
  },
  {
    path: "customers",
    Component: loadModules("Customer/Customer"),
  },
  {
    path: "customers/:id",
    Component: loadModules("Customer/CustomerTransaction"),
  },
  {
    path: "transactions",
    Component: loadModules("Transaction/Transaction"),
  },
  {
    path: "transactions/:reference",
    Component: loadModules("Transaction/TransactionDetail"),
  },
  {
    path: "payouts",
    Component: loadModules("Payouts/Payout"),
  },
  {
    path: "payouts/:id",
    Component: loadModules("Payouts/PayoutDetails"),
  },
  {
    path: "dev-payouts/:id",
    Component: loadModules("Payouts/DevRefDetails"),
  },
  {
    path: "swaps",
    Component: loadModules("Swap/Swap"),
  },

  {
    path: "business",
    Component: loadModules("Business/Business"),
  },
  {
    path: "business/:id/info",
    Component: loadModules("Business/BusinessInfo"),
  },
  {
    path: "business/:id",
    Component: loadModules("Business/BusinessDetails"),
  },
  {
    path: "business/:id/activities",
    Component: loadModules("Business/BusinessActivities"),
  },
  {
    path: "business/:id/transactions",
    Component: loadModules("Business/BusinessTransactions"),
  },
  {
    path: "ivory-storefront/orders/:id",
    Component: loadModules("IvoryStorefront/OrderDetails"),
  },
  {
    path: "ivory-storefront/transactions/:id",
    Component: loadModules("IvoryStorefront/IvoryStorefrontTransactionDetails"),
  },
  {
    path: "ivory-storefront/about/:id",
    Component: loadModules("IvoryStorefront/AboutStorefront"),
  },

  {
    path: "ivory-storefront",
    Component: loadModules("IvoryStorefront/IvoryStorefront"),
  },
  {
    path: "ivory-storefront/:id",
    Component: loadModules("IvoryStorefront/IvoryStorefrontDetails"),
  },
  {
    path: "payment-link",
    Component: loadModules("PaymentLink/PaymentLink"),
  },
  {
    path: "payment-link/:id",
    Component: loadModules("PaymentLink/PaymentTransaction"),
  },
  {
    path: "settings",
    Component: loadModules("Settings/Settings"),
  },
  {
    path: "settings/roles",
    Component: loadModules("Settings/AddRole"),
  },
  {
    path: "settings/roles/:id",
    Component: loadModules("Settings/EditRole"),
  },
  {
    path: "users",
    Component: loadModules("Users/Users"),
  },
  {
    path: "users/:id",
    Component: loadModules("Users/UserDetails"),
  },
  {
    path: "developers",
    Component: loadModules("Developers/Developers"),
  },
  {
    path: "developers/:id",
    Component: loadModules("Developers/DeveloperDetails"),
  },
  {
    path: "users/:id/activities",
    Component: loadModules("Users/UserActivities"),
  },
  {
    path: "notifications",
    Component: loadModules("Notifications/Notifications"),
  },
  {
    path: "discounts",
    Component: loadModules("Discounts/Discounts"),
  },
  {
    path: "discounts/:type",
    Component: loadModules("Discounts/Discounts"),
  },
  {
    path: "direct-transfer",
    Component: loadModules("DirectTransfer/DirectTransfer"),
  },
];

export const authRoutes: RouteConfig[] = [
  {
    path: "login",
    Component: loadModules("Auth/Login"),
  },
];
